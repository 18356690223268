import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LifeCycle } from '@wix/thunderbolt-symbols'
import { TpaHandlersDistributor } from './tpaHandlersDistributor'
import { MasterPageTpaPropsCacheSymbol, TpaSrcBuilderSymbol } from './symbols'
import { TpaPropsCacheFactory } from './tpaPropsCache'
import { TpaSrcBuilder } from './tpaSrcBuilder'

export const site: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.AppDidMountHandler).to(TpaHandlersDistributor)
	bind(MasterPageTpaPropsCacheSymbol).to(TpaPropsCacheFactory)
	bind(TpaSrcBuilderSymbol).to(TpaSrcBuilder)
}

export { TpaHandlersManagerSymbol, name } from './symbols'
export { MasterPageTpaPropsCacheSymbol, TpaSrcBuilderSymbol }
export * from './types'
